<template>
  <div class="flex items-center space-x-3">
    <router-link  :to="{ name: 'Miniviews'}">
      <span class="underline text-sm font-bold">MINIVIEWs™</span>
    </router-link>
    <span class="text-sm font-normal capitalize truncate text-grayDark"></span>
    <span class=" text-sm font-normal capitalize truncate text-grayDark">{{ miniview.title }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name:"MiniviewsNavigation",

  computed: {
      ...mapGetters({
      miniview: 'miniviews/current',
    }),
  },
}
</script>
