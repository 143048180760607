import api from '@/api'

const shows = {
  namespaced: true,

  state: {
    show: {},
    shows: [],
    pagination: {
      total: 0,
      count: 0,
      per_page: 15,
      current_page: 1,
      total_pages: 1,
      links: {}
    },

    availableInterviewDurations: [ 15, 30, 45, 60, 75, 90, 120, 180 ],
  },

  getters: {
    shows: state => state.shows,
    pagination(state) {
      return state.pagination
    },
    show: state => state.show,

    availableInterviewDurations: state => state.availableInterviewDurations,
  },

  actions: {
    create({ commit }, payload) {
      return api.post('/shows', payload)
        .then(({ data }) => {
          commit('setShow', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetch({ commit }, payload) {
      let url = `/shows?page=${payload.page}`;
      if (payload.tags) {
        url += `&filter[tag]=${payload.tags}`
      }
      if (payload.categories) {
        url += `&filter[category]=${payload.categories}`
      }
      if (payload.name) {
        url += `&filter[show_profiles.name]=${payload.name}`
      }
      if (payload.prices) {
        url += `&filter[prices]=${payload.prices}`
      }
      if (payload.rating) {
        url += `&filter[rating]=${payload.rating}`
      }
      if (payload.featured) {
        url += `&filter[featured]=${payload.featured}`
      }
      if (payload.options) {
        url += `&filter[options]=${payload.options}`
      }
      if (payload.isGuestioTalent) {
        url += `&filter[is_guestio_talent]=${payload.isGuestioTalent}`
      }
      if (payload.premium) {
        url += `&filter[premium]=${payload.premium}`
      }
      if (payload.sortBy) {
        url += `&sort=${payload.sortBy}`
      }
      return api.get(url)
        .then(({ data }) => {
          commit('setShows', data.data)
          commit('setPagination', data.meta)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchShow({ commit }, payload) {
      let url = `/shows/${payload.id}`;
      if (payload.includes) {
        url += `?include=${payload.includes}`
      }
      return api.get(url)
        .then(({ data }) => {
          commit('setShow', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    updateShow({ commit }, payload) {
      return api.put(`/shows/${payload.id}`, payload)
        .then(({ data }) => {
          commit('setShow', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    deleteMedia(context, payload) {
      return api.delete(`/shows/${payload.model_id}/media/${payload.uuid}`)
        .then(() => {
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchShowLinks({ commit }, payload) {
      let url = `/shows/${payload.id}/links`;
      if (payload.excludes) {
        url += `?exclude=${payload.excludes}`
      }
      return api.get(url)
        .then(({ data }) => {
          commit('setShowLinks', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    addPastGuest(context, payload) {
      return api.post(`/shows/${payload.show_profile_id}/past-guests`, {
        guest_profile_id: payload.guest_profile_id,
      })
        .then(({ data }) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    removePastGuest(context, payload) {
      return api.delete(`/shows/${payload.show_profile_id}/past-guests/${payload.guest_profile_id}`)
        .then(({ data }) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchBookingQuestions(context, showId) {
      return api.get(`/shows/${showId}/booking-questions`)
    },

    fetchPitchQuestions(_, showId) {
      return api.get(`/shows/${showId}/booking-questions?type=pitch`);
    },

    fetchBookingFlowPreferences(context, showId) {
      return api.get(`/shows/${showId}/booking-flow-preferences`)
    },

    updateBookingFlowPreferences(context, params) {
      return api.put(`/shows/${params.showId}/booking-flow-preferences`, {updatedPreferences: params.updatedPreferences})
    },

    storeBookingQuestion(context, payload) {
      return api.post(`/shows/${payload.showId}/booking-questions`, payload)
    },

    updateBookingQuestion(context, payload) {
      return api.put(`/shows/${payload.showId}/booking-questions/${payload.questionId}`, payload)
    },

    deleteBookingQuestion(context, payload) {
      return api.delete(`/shows/${payload.showId}/booking-questions/${payload.questionId}`)
    },

    fetchUpsellPackages(context, showId) {
      return api.get(`/shows/${showId}/upsell-packages`)
    },

    storeUpsellPackage(context, payload) {
      return api.post(`/shows/${payload.showId}/upsell-packages`, payload)
    },

    updateUpsellPackage(context, payload) {
      return api.put(`/shows/${payload.showId}/upsell-packages/${payload.packageId}`, payload)
    },

    deleteUpsellPackage(context, payload) {
      return api.delete(`/shows/${payload.showId}/upsell-packages/${payload.packageId}`)
    },

    fetchShowTypes() {
      return api.get('/show-types');
    },

    updateProfileStats(context, payload) {
      return api.post(`/shows/${payload.showId}/profile-stats`, payload)
    },

    cancelVerificationRequest(context, payload) {
      return api.patch(`/shows/${payload.showId}/profile-stats/${payload.statId}/cancel-verification-request`, payload)
    },

    fetchPitchSettings(context, showId) {
      return api.get(`/shows/${showId}/pitch-settings`);
    },

    updatePitchSettings(context, params) {
      return api.put(`/shows/${params.modelId}/pitch-settings`, params);
    },

    updateRephonicStats(context, payload) {
      return api.put(`/shows/${payload.showId}/rephonic-stats`, { rephonic_id: payload.rephonic_id})
    },
  },

  mutations: {
    setShow(state, data) {
      state.show = {
        ...state.show,
        ...data
      }
    },

    setShowLinks(state, data) {
      state.show = {
        ...state.show,
        links: data
      }
    },

    setShows(state, data) {
      state.shows = data;
    },

    clearShow(state) {
      state.show = {}
    },

    setPagination(state, data) {
      if (data.pagination) {
        state.pagination = data.pagination;
      }
    },
  },
}

export default shows
