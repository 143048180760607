import { render, staticRenderFns } from "./GuestioConnectCard.vue?vue&type=template&id=480b3966&scoped=true"
import script from "./GuestioConnectCard.vue?vue&type=script&lang=js"
export * from "./GuestioConnectCard.vue?vue&type=script&lang=js"
import style0 from "./GuestioConnectCard.vue?vue&type=style&index=0&id=480b3966&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "480b3966",
  null
  
)

export default component.exports