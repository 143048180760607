const routes = [
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/auth/ForgotPassword.vue'),
    meta: {
      guest: true,
    },
  },
  {
    path:'/complete-account/:token',
    name: 'complete-account',
    component: () => import('../views/auth/register/CompleteAccount.vue'),
    meta: {
      guest: true,
    },
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password-form',
    component: () => import('../views/auth/ResetPasswordForm.vue'),
    meta: {
      guest: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/auth/Login.vue'),
    meta: {
      guest: true,
    },
  },
  {
    path: '/login/:provider',
    name: 'SocialiteLogin',
    component: () => import('../views/SocialiteLogin.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/auth/Logout.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/impersonate',
    meta: {
      isImpersonate: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/auth/Register.vue'),
    // beforeEnter: guest,
    children: [
      {
        path: '/',
        name: 'register-personal-information',
        component: () => import('../views/auth/register/PersonalInformation'),
        meta: {
          guest: true,
        },
      },
      {
        path: 'subscription',
        name: 'register-subscription',
        component: () => import('../views/auth/register/ProSubscription'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'profile-type',
        name: 'register-profile-type',
        component: () => import('../views/auth/register/ProfileType'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'profile-setup',
        name: 'register-profile-setup',
        component: () => import('../views/auth/register/ProfileSetup'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'guest-social-links',
        name: 'register-guest-social-links',
        component: () => import('../views/auth/register/GuestSocialLinks'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'availability',
        name: 'register-availability',
        component: () => import('../components/register/ProfilesAvailability'),
        meta: {
          requiresAuth: true,
        },
      },
    ]
  },
  {
    path: '/register-pro',
    name: 'register-pro',
    component: () => import('../views/auth/RegisterPro.vue'),
    meta: {
      guest: true,
    },
  },
];

export default routes;
